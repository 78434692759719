<template>
  <Pending v-if="pending">Chargement en cours...</Pending>
  <PageLayout v-else>
    <template #header>
      <h2 class="display-3 text-center">Zones de stockage</h2>
      <hr />
      <BaseLegend>
        <p v-if="!$route.query.board">
          Dans cette section, vous pouvez ajouter, supprimer et/ou renommer vos
          zones de stockage.
        </p>
        <p v-else>Choisissez une zone de stockage.</p>
      </BaseLegend>
    </template>
    <template #default>
      <div class="gap-2 p-3">
        <template v-if="zones.length">
          <router-link
            custom
            v-slot="{ navigate }"
            :to="{
              name: 'AreasList',
              params: { zoneId: zone.id },
              query: { board: $route.query.board },
            }"
            v-for="zone in zones"
            :key="zone.id"
          >
            <Card @click="!editMode ? navigate() : () => null">
              <template #content>
                <BaseInplace
                  v-model="zone.name"
                  @edit="(value) => onEdit(zone.id, value)"
                  @onEdit="(context) => (editMode = context)"
                >
                  <Button
                    icon="fa fa-trash-alt"
                    class="p-button-text p-button-danger"
                    @click.stop="onRemove(zone.id)"
                    :disabled="editMode"
                  />
                </BaseInplace>
              </template>
            </Card>
          </router-link>
        </template>
        <template v-else>
          <BaseNotFound
            @action="addDialog = true"
            message="Aucune zone trouvée"
            detail="Ajouter une zone"
          />
        </template>
      </div>
    </template>
    <template #footer>
      <ActionsNav>
        <router-link
          custon
          v-slot="{ navigate }"
          :to="
            $route.query.board
              ? {
                  name: 'BoardDetails',
                  params: { boardId: $route.query.board },
                }
              : { name: 'Boards' }
          "
        >
          <BaseButtonSet
            label="Retour"
            color="secondary"
            icon="fa fa-arrow-left"
            text
            rtl
            @click="navigate"
          ></BaseButtonSet>
        </router-link>
        <BaseButtonSet
          label="Ajouter une zone"
          color="success"
          icon="fa fa-plus"
          @click="addDialog = true"
          v-if="!user.roles?.moderator"
        ></BaseButtonSet>
      </ActionsNav>
    </template>
  </PageLayout>
  <Dialog
    modal
    v-model:visible="addDialog"
    header="Ajouter une zone"
    @show="onShow"
    style="min-width: 90vw"
  >
    <template #default>
      <form @submit.prevent="onAdd" id="form-add">
        <div class="p-field p-fluid">
          <label class="mb-1">Nom</label>
          <InputText placeholder="Ex: Cave à vins" v-model="name" />
        </div>
      </form>
    </template>
    <template #footer>
      <Button
        label="Retour"
        class="p-button-secondary p-button-text"
        @click="addDialog = false"
      />
      <Button
        label="Ajouter"
        class="p-button-success"
        type="submit"
        form="form-add"
        :disabled="!name"
      />
    </template>
  </Dialog>
</template>

<script>
import { useRoute } from "vue-router";
import useDatabase from "../../composables/useDatabase";
import { getUser } from "../../composables/getUser";
import { useConfirm } from "primevue/useconfirm";
import { defineComponent, ref, reactive, toRefs, nextTick } from "vue";
import { find } from "lodash";
import { useToast } from "primevue/usetoast";
import { projectDatabase } from "../../firebase/config";

export default defineComponent({
  props: ["board"],
  setup() {
    const route = useRoute();
    const user = getUser();
    const confirm = useConfirm();
    const toast = useToast();
    const { centerId } = route.params;

    const { getAll, addOne, removeOne } = useDatabase(
      `centers/${centerId}/zones`
    );
    const { docs: zones, error, pending } = getAll();

    const editMode = ref(false);
    const editData = ref("");

    const onEditMode = (id, data) => {
      editMode.value = id;
      editData.value = data;
    };

    const onEdit = async (id, data) => {
      if (data !== editData.value) {
        await projectDatabase.ref(`centers/${centerId}/zones/${id}`).update({
          name: data,
        });
        toast.add({
          severity: "info",
          summary: "Informations",
          detail: `La zone a bien été renommée !`,
          life: 2500,
        });
      }
      editMode.value = false;
    };

    const onRemove = (id) => {
      const zoneToRemove = find(zones.value, (zone) => zone.id === id);
      if (zoneToRemove.areas) {
        toast.add({
          severity: "error",
          summary: "La zone doit être vide",
          detail:
            "Veuillez supprimer tous les rangements de la zone avant de continuer.",
          life: 3500,
        });
      } else {
        confirm.require({
          header: `Suppression`,
          message: `Êtes-vous certain de vouloir supprimer la zone ${zoneToRemove.name} ?`,
          acceptClass: "p-button-danger",
          acceptLabel: "Supprimer",
          rejectClass: "p-button-secondary p-button-text",
          rejectLabel: "Retour",
          accept: async () => {
            await removeOne(id);
            toast.add({
              severity: "info",
              summary: "Informations",
              detail: `La zone ${zoneToRemove.name} à bien été supprimée !`,
              life: 2500,
            });
          },
        });
      }
    };

    const addDialog = ref(false);
    const newZone = reactive({
      name: "",
    });
    const onAdd = async () => {
      await addOne(newZone);
      newZone.name = "";
      addDialog.value = false;
      toast.add({
        severity: "info",
        summary: "Informations",
        detail: `La zone ${newZone.name} à bien été ajoutée !`,
        life: 2500,
      });
    };

    const onShow = () => {
      const input = document.querySelector("#form-add").querySelector("input");
      nextTick().then(() => {
        input.focus();
      });
    };

    return {
      user,
      zones,
      error,
      pending,
      editMode,
      onEditMode,
      onEdit,
      onRemove,
      onAdd,
      addDialog,
      ...toRefs(newZone),
      onShow,
    };
  },
});
</script>

<style lang="scss" scoped></style>
